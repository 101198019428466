'use client';
import { EkkoLogo } from '@/components/common/EkkoLogo';
import HideOnScroll from '@/components/common/HideOnScroll';
import NavigationDrawer, { toggleDrawer } from '@/components/common/NavigationDrawer';
import { useProGlove } from '@/components/proglove/ProgloveProvider';
import { useThemeBreakpoint } from '@/components/useThemeBreakpoint';
import { authenticationAtom } from '@/context/atoms/Authentication';
import { jwtAuthMechanismAtom } from '@/context/atoms/JWTAuthMechanism';
import { navigationIsOpen } from '@/context/atoms/navigation/NavigationIsOpen';
import { soundAtom } from '@/context/atoms/Sound';
import { ThemeMode, themeModeAtom } from '@/context/atoms/themeMode';
import { useAppModules } from '@/context/modules';
import {
  AccountCircle,
  DarkModeOutlined,
  Fullscreen,
  FullscreenExit,
  LightMode,
  Logout,
  Menu as MenuIcon,
  SettingsBrightness,
  SportsMma,
  Star,
  VolumeOff,
  VolumeUp,
} from '@mui/icons-material';
import { Backdrop, Divider, ListItemIcon, ListItemText, MenuList, Slide, SvgIcon } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useAtom } from 'jotai';
import { usePathname, useRouter } from 'next/navigation';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

export default function PrimaryAppBar(): React.JSX.Element {
  const [themeMode, setThemeMode] = useAtom(themeModeAtom);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const min600 = useMediaQuery('(min-width: 600px)');
  const theme = useTheme();
  const [auth] = useAtom(jwtAuthMechanismAtom);
  const [sound, setSound] = useAtom(soundAtom);
  const [, setAuthentication] = useAtom(authenticationAtom);
  const [isOpen, setOpen] = useAtom(navigationIsOpen);
  const [barTitle, setBarTitle] = useState('');

  const pathname = usePathname();
  const router = useRouter();
  const [title, setTitle] = useState<string | null>(null);
  const { getTitleByPathname } = useAppModules();

  const isMenuOpen = Boolean(anchorEl);

  const hasProGloveConfig = true;
  const { connect, connected, scannerConnectivity, connectedOnce, scannerConnected } = useProGlove();

  const tbp = useThemeBreakpoint();

  const titleTransitionCallback = useCallback(() => {
    setBarTitle(title ?? '');
  }, [title]);

  useEffect(() => {
    if (pathname !== null) {
      setTitle(getTitleByPathname(pathname));
    }
  }, [getTitleByPathname, pathname]);

  useEffect(() => {
    if (title !== null) {
      setBarTitle(title);
    }
  }, [title]);

  const handleFavorites = (event: React.MouseEvent<HTMLElement>): void => {
    event.preventDefault();
    event.stopPropagation();
    router.push('/favorites');
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  const logout = useCallback(async (): Promise<void> => {
    setAuthentication({
      isAuthenticated: false,
    });
    await auth.invalidateAuthentication();
  }, [auth, setAuthentication]);

  const handleLogout = useCallback((): void => {
    void logout();
  }, [logout]);

  const fullscreenToggle = (): void => {
    if (document.fullscreenElement !== null) {
      void document.exitFullscreen();
    } else {
      void document.body.requestFullscreen();
    }
    setAnchorEl(null);
  };

  const soundToggle = useCallback((): void => {
    setSound({
      enabled: !sound.enabled,
      volume: sound.volume,
    });
  }, [sound, setSound]);

  const handleThemeMode = useCallback(
    (themeMode: ThemeMode) => (): void => {
      setThemeMode(themeMode);
    },
    [setThemeMode],
  );

  const menuId = 'primary-search-account-menu';

  const renderMenu = useMemo(() => {
    return (
      <Menu
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id={menuId}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem disabled={true}>
          <ListItemText>{auth.authentication.user?.username}</ListItemText>
          <ListItemIcon sx={{ justifyContent: 'end' }}>
            <AccountCircle />
          </ListItemIcon>
        </MenuItem>
        <Divider sx={{ minWidth: 200 }} />
        <MenuItem onClick={fullscreenToggle}>
          <ListItemText>Fullscreen</ListItemText>
          <ListItemIcon sx={{ justifyContent: 'end' }}>
            {document.fullscreenElement === null ? <Fullscreen /> : <FullscreenExit />}
          </ListItemIcon>
        </MenuItem>
        <MenuItem onClick={soundToggle}>
          <ListItemText>Sound</ListItemText>
          <ListItemIcon sx={{ justifyContent: 'end' }}>{sound.enabled ? <VolumeUp /> : <VolumeOff />}</ListItemIcon>
        </MenuItem>
        <Divider sx={{ minWidth: 200 }} />
        <MenuItem dense disabled={true}>
          <ListItemText>Theme</ListItemText>
          <Typography variant="body2">{themeMode}</Typography>
        </MenuItem>
        <MenuList sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <MenuItem onClick={handleThemeMode(ThemeMode.LIGHT)}>
            <LightMode color={themeMode === ThemeMode.LIGHT ? 'secondary' : 'inherit'} />
          </MenuItem>
          <MenuItem onClick={handleThemeMode(ThemeMode.SYSTEM)}>
            <SettingsBrightness color={themeMode === ThemeMode.SYSTEM ? 'secondary' : 'inherit'} />
          </MenuItem>
          <MenuItem onClick={handleThemeMode(ThemeMode.DARK)}>
            <DarkModeOutlined color={themeMode === ThemeMode.DARK ? 'secondary' : 'inherit'} />
          </MenuItem>
        </MenuList>
        <Divider sx={{ minWidth: 200 }} />
        {hasProGloveConfig && (
          <MenuItem
            onClick={() => {
              if (!connected) {
                void connect();
              } else {
                scannerConnectivity();
              }
            }}
          >
            <ListItemText>ProGlove</ListItemText>
            <ListItemIcon sx={{ justifyContent: 'end' }}>
              <SportsMma color={connected ? (scannerConnected ? 'secondary' : 'info') : 'error'} />
            </ListItemIcon>
          </MenuItem>
        )}
        {hasProGloveConfig && <Divider sx={{ minWidth: 200 }} />}
        <MenuItem onClick={handleLogout}>
          <ListItemText>Logout</ListItemText>
          <ListItemIcon sx={{ justifyContent: 'end' }}>
            <Logout />
          </ListItemIcon>
        </MenuItem>
      </Menu>
    );
  }, [
    anchorEl,
    isMenuOpen,
    auth.authentication.user?.username,
    soundToggle,
    sound.enabled,
    themeMode,
    handleThemeMode,
    hasProGloveConfig,
    connected,
    scannerConnected,
    handleLogout,
    connect,
    scannerConnectivity,
  ]);

  const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

  return (
    <Box sx={{ height: [56, 64], flexGrow: 1, zIndex: (theme) => theme.zIndex.drawer - 1 }}>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer - 1 }} open={isMenuOpen} onClick={handleMenuClose} />
      <NavigationDrawer />
      <HideOnScroll>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              aria-label="open drawer"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(!isOpen, setOpen)}
            >
              <MenuIcon sx={{ color: theme.palette.secondary.main }} />
            </IconButton>
            <SvgIcon component={EkkoLogo} sx={{ color: 'white', fontSize: 56 }} />
            <Slide
              direction="down"
              in={title !== null}
              mountOnEnter
              unmountOnExit
              onEntering={titleTransitionCallback}
              onExited={titleTransitionCallback}
            >
              <Typography
                variant="h6"
                noWrap
                component="div"
                sx={{
                  fontWeight: 700,
                  fontSize: '25px',
                  lineHeight: min600 ? '64px' : '55px',
                  alignSelf: 'end',
                  marginLeft: '8px',
                  display: { sm: 'block' },
                  color: theme.palette.secondary.main,
                }}
              >
                {barTitle}
              </Typography>
            </Slide>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
              {connectedOnce && (
                <SportsMma
                  sx={{
                    color: connected
                      ? scannerConnected
                        ? theme.palette.secondary.main
                        : theme.palette.info.main
                      : theme.palette.error.main,
                    mr: tbp === 'xs' ? 1 : 2,
                  }}
                  onClick={() => {
                    if (!connected) {
                      void connect();
                    } else {
                      scannerConnectivity();
                    }
                  }}
                />
              )}
              <IconButton
                size="large"
                edge="end"
                aria-label="favorites"
                onClick={handleFavorites}
                color="inherit"
                sx={{ mr: tbp === 'xs' ? 1 : 2 }}
              >
                <Star sx={{ color: theme.palette.secondary.main }} />
              </IconButton>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle sx={{ color: theme.palette.secondary.main }} />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Offset />
      {renderMenu}
    </Box>
  );
}
